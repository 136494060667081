.home-header-content{
    display: flex;
    justify-content: space-between;
    // background-color: blue;
    background: rgba(191, 210, 243, 0.3);
    padding:26px 16px 16px 16px;
}

.pharmacy-card{
    background: rgba(191, 210, 243, 0.3);
    padding: 0px 16px 16px 16px;

    .base-stars{
       
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 16px;
        
        .two-three{
            font-size: 12px;
            font-weight: 400;
            color:rgba(41, 55, 95, 0.6);
        }
    }

    .center-content{
        display: flex;
        justify-content:center;
    }
    .space-btw{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .apollo{
        font-weight: 700;
        font-size: 16px;
        color:#29375F;
        text-align: center;
        padding: 8px 0px 4px 0px;
    }
    .hsr{
        font-weight: 400;
        font-size: 14px;
        color: rgba(41, 55, 95, 0.6);
        text-align: center;
        padding-bottom: 20px;

    }


}

.direction-button{
    --background: white !important;
    --background-hover:transparent;
    --background-activated:white;
    width: fit-content;
    height: 36px;
    --box-shadow: none;

    .cont{
        font-size: 14px;
        font-weight: 400;
        color: #29375F;
        padding-left: 8px;
       

    }
   
}