.verify-num{
    padding:24px 16px;
   
    .con-1{
        font-size: 18px;
        font-weight: 600;
        color: #29375F;
        padding-bottom: 10px;
    }
    .con-2{
        font-size: 14px;
        font-weight: 400;
        color: rgba(41, 55, 95, 0.7);
        padding-bottom: 24px;
        span{
            font-weight: 500;
            font-size: 14px;
            color:#FC6964;
        }

    }
    .in{
        border: 1px solid rgba(192, 206, 255, 0.7);
border-radius: 7px;
width: 100%;
height: 50px;
outline: none;
margin-bottom: 16px;
    }
  

}
.foot-ver{
    padding:30px;
    .con-2{
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: rgba(41, 55, 95, 0.7);
        padding-bottom: 24px;
        padding-top: 24px;
        span{
            font-weight: 500;
            font-size: 14px;
            color:#FC6964;
        }

    }
}
.proceed{
   
    width: 100%;
    background: rgba(252, 105, 100, 0.5);
border-radius: 6px;
padding: 15px;
color: white;
font-weight: 700;
font-size: 18px;
}

.verify-footer{
   
    
   
    width: 100%;
    background: #FC6964;
border-radius: 6px;
padding: 15px;
color: white;
font-weight: 700;
font-size: 18px;
}


#verify{
    --background:white;
    --height:35vh;
    padding-top: 65vh;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
--border-radius: 10px 10px 0 0;

}

#Otpverify{
    --background:white;
    --height:50vh;
    padding-top: 50vh;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
--border-radius: 10px 10px 0 0;

}