#review-page{
    .review-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(191, 210, 243, 0.3);
        padding: 41px 27px 27px ;
        .title-1{
            font-size: 16px;
            font-weight: 700;
            color: #29375F;
        }
        .hide{
            visibility: hidden;
        }

    }
    ion-content{
        .bord{
            border:1px solid #E0E0E0;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}