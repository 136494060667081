.more-review-card{
    box-shadow: none;
    margin:0;
    .flex-1{
        // display: flex;
        // justify-content: center;
        background-color:#ecf2fc;
        padding:30px;
    }
    .first-part{

       font-size: 16px;
        font-weight: 400;
        color: #29375F;
        .two-3{
            display: flex;
            justify-content: center;
            .sub-t{
            font-size: 12px;
            font-weight: 400;
            color:rgba(41, 55, 95, 0.6);}
        }
        .view{
            padding-top: 4px;
            display: flex;
            justify-content: center;
        }
       
        
        span{
            
            font-size: 16px;
            font-weight: 700;
            color: #29375F;
        }
    }
    .second-part{
        background-color: #cdd6e6;
        display: flex;
        justify-content: center;
        padding: 14px;
        font-size: 14px;
        font-weight: 500;
        color: #29375F;
    }

}