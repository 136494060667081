.customer-review-card{
    
    box-shadow: none;
    margin: 0;
    background: rgba(192, 206, 255, 0.18);
    border-radius: 10px;
    padding: 16px 10px ;
    .flex-cen{
        display: flex;
        align-items: center;
        gap: 16px;
        .lokesh{
            font-weight: 700;
            font-size: 13px;
            color: #29375F;
            padding-bottom: 4px;

        }
    }
    .paragraph{
        padding-top: 16px;
        font-size: 14px;
        font-weight: 400;
        color:#29375F;
    }
}

.white-customer-card{
    @extend .customer-review-card;
    background:#fff
}