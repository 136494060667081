.kr-4all-card{
    border-radius: 10px;
    box-shadow:none;
    margin: 0;
}
    
    
    .kr4-card{
        position: relative;
        margin-top: 50px;
        img{
            position: absolute;
            right: 0px;
            top: -50px;
        }
        .top-1{
            border-radius: 10px 10px 0 0 ;
            background: linear-gradient(265.39deg, #8FC985 0%, #D9FFBA 99.17%);
            height: 108px;
            .heading{
                padding:16px 117px 8px 21px;
                font-size: 16px;
                font-weight: 700;
                color: #244D1D;;
            }
            .heading-2{
                padding:0px 117px 8px 21px;
                
                font-size: 12px;
                font-weight: 400;
                color: rgba(36, 77, 29, 0.8);

                ;
    
            }
        }
    
    }
    
    .kr-4-foot{
        background:linear-gradient(90deg,#83cb6b,#5ca651) ;
    // mix-blend-mode: multiply;
    border-radius: 0px 0px 10px 10px;
    font-weight: 500;
    font-size: 14px;
    color: white;
    text-align: center;
    padding: 14px;
    }

    