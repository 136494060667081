#how-it-w{
  .title-how{
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      color: #29375F;
      padding:32px 0 16px 0
  }
  ion-content{
      .magin{
          margin-left: 24px;
          margin-right: 24px;
      }
      
  }
  ion-footer{
    padding: 24px 32px 24px 32px;
    .btn{
        --background-activated:transparent;
        width: 100%;
        --background: #FC6964;
        font-weight: 700;
        font-size: 18px;
        color: white;
    }

}

}