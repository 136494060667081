.upload-pres-card{
border-radius: 10px;
box-shadow:none;
margin: 0;

}
.image-pos{
    position: relative;
    margin-top: 50px;
    img{
        position: absolute;
        right: 0px;
        top: -50px;
    }
    .top-1{
        border-radius: 10px 10px 0 0 ;
        background: linear-gradient(265.39deg, #FFB0A7 0%, #FFDFBA 99.17%);
        height: 108px;
        .heading{
            padding:16px 117px 8px 21px;
            font-size: 16px;
            font-weight: 700;
            color: #63392C;
        }
        .heading-2{
            padding:0px 117px 8px 21px;
            
            font-size: 12px;
            font-weight: 400;
            color: rgba(99, 57, 44, 0.8);

        }
    }

}

.foot{
    background: #FF9A82;
// mix-blend-mode: multiply;
border-radius: 0px 0px 10px 10px;
font-weight: 500;
font-size: 14px;
color: white;
text-align: center;
padding: 14px;
}
