.how-it{
    margin: 0;

    background-color: #ECF2FC;
    padding: 20px;
    box-shadow: none;

    .flex-1{
        display: flex ;
        align-items: center;
        img{
            width: 20px;
            height: 20px;
        }
        .share{
            font-size: 12px;
            font-weight: 400;
            color: #29375F;
            padding-left: 15px;
        }
    }

    .outer-box{
        width:20px;
        height: 20px;
        
        .inner-box{
            border:  1px dashed rgba(41, 55, 95, 0.61);
            transform: rotate(90deg);
            margin-top: 15px;
            
        }
    }
}