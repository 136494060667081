#root {
  height: 100%;
}
#mobile-frame {
  display: flex;
  justify-content: center;
  height: 100%;

  .device {
    display: inline-block;
    position: relative;
    box-sizing: content-box !important;
    transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: 375px;

    padding: 26px;
    background: #fdfdfd;
    // box-shadow: inset 0 0 11px 0 black;
    border-radius: 66px;

    &::before {
      width: calc(100% - 10px);
      height: calc(100% - 1000px);
      position: absolute;
      top: 5px;
      content: "";
      left: 5px;
      border-radius: 61px;
      background: black;
      z-index: 1;
    }
  }
}
