#home-screen {
  ion-content {
    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    --background: white !important;

    .avatar {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      img {
        object-fit: contain;
      }
    }

    .mar-25 {
      margin-left: 24px;
      margin-right: 24px;
    }
    .how-it-p {
      padding-top: 32px;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 700;
      color: #29375f;
      text-align: center;
    }
    .discount-off {
      border: 1px solid rgba(41, 55, 95, 0.2);
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 12px;
      margin-top: 24px;
      .title {
        font-size: 14px;
        font-weight: 600;
        color: #29375f;
      }
      .sub-t {
        @extend .title;
        font-weight: 400;
      }
    }
  }
  ion-footer {
    padding: 12px;
    .btn {
      --background-activated: transparent;
      width: 100%;
      --background: #fc6964;
      font-weight: 700;
      font-size: 18px;
      color: white;
      --box-shadow: none;
    }
  }
}
